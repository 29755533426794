import logo from './logo.svg';
import React,{Suspense} from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HompePage from './pages/homepage'
import Body from './dashboard/body'
const Homepage = React.lazy(() => import('./pages/homepage'));
const CKEditor = React.lazy(() => import('./ckeditor/ckeditor'));
const BlogList = React.lazy(() => import('./bloglist/listblog'));
const EditBlog = React.lazy(() => import('./ckeditor/editblog'));





function App() {
  return (
    <BrowserRouter>
<HompePage>
  <Suspense>
    <Routes>
        <Route path="/" element={<Body />} />
         <Route path="/blog"
            element={<CKEditor />} />
             <Route path="/blogedit"
            element={<EditBlog />} />

        <Route path="/blogList"
            element={<BlogList />} />
    </Routes>
    </Suspense>
    </HompePage>
</BrowserRouter>

  );
}

export default App;
