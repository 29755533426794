import React from 'react'

const Sidebar = () => {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
    <ul className="nav">
      <li className="nav-item sidebar-category">
        <p>Navigation</p>
        <span />
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/">
          <i className="mdi mdi-view-quilt menu-icon" />
          <span className="menu-title">Dashboard</span>
          {/* <div className="badge badge-info badge-pill">2</div> */}
        </a>
      </li>
      <li className="nav-item sidebar-category">
        <p>Components</p>
        <span />
      </li>
      {/* <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="collapse"
          href="#ui-basic"
          aria-expanded="false"
          aria-controls="ui-basic"
        >
          <i className="mdi mdi-palette menu-icon" />
          <span className="menu-title">UI Elements</span>
          <i className="menu-arrow" />
        </a>
        <div className="collapse" id="ui-basic">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                Buttons
              </a>
            </li>
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                Typography
              </a>
            </li>
          </ul>
        </div>
      </li> */}
      <li className="nav-item">
        <a className="nav-link" href="/blog">
          <i className="mdi mdi-view-headline menu-icon" />
          <span className="menu-title">Blogs</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/bloglist">
          <i className="mdi mdi-view-headline menu-icon" />
          <span className="menu-title">Blog List</span>
        </a>
      </li>
      {/* <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="mdi mdi-view-headline menu-icon" />
          <span className="menu-title">Form elements</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="mdi mdi-chart-pie menu-icon" />
          <span className="menu-title">Charts</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="mdi mdi-grid-large menu-icon" />
          <span className="menu-title">Tables</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="mdi mdi-emoticon menu-icon" />
          <span className="menu-title">Icons</span>
        </a>
      </li>
      <li className="nav-item sidebar-category">
        <p>Pages</p>
        <span />
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="collapse"
          href="#auth"
          aria-expanded="false"
          aria-controls="auth"
        >
          <i className="mdi mdi-account menu-icon" />
          <span className="menu-title">User Pages</span>
          <i className="menu-arrow" />
        </a>
        <div className="collapse" id="auth">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                {" "}
                Login{" "}
              </a>
            </li>
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                {" "}
                Login 2{" "}
              </a>
            </li>
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                {" "}
                Register{" "}
              </a>
            </li>
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                {" "}
                Register 2{" "}
              </a>
            </li>
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="#">
                {" "}
                Lockscreen{" "}
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li className="nav-item sidebar-category">
        <p>Apps</p>
        <span />
      </li> */}
      {/* <li className="nav-item">
        <a className="nav-link" href="docs/documentation.html">
          <i className="mdi mdi-file-document-box-outline menu-icon" />
          <span className="menu-title">Documentation</span>
        </a>
      </li> */}
      {/* <li className="nav-item">
        <a
          className="nav-link"
          href="http://www.bootstrapdash.com/demo/spica/template/"
        >
          <button className="btn bg-danger btn-sm menu-title">
            Upgrade to pro
          </button>
        </a>
      </li> */}
    </ul>
  </nav>  
  )
}

export default Sidebar