import React from 'react'

const Footer = () => {
  return (
<footer className="footer">
  <div className="card">
    <div className="card-body">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
          Copyright © bootstrapdash.com 2020
        </span>
        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
          Distributed By:{" "}
          <a href="https://www.themewagon.com/" target="_blank">
            ThemeWagon
          </a>
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          {" "}
          Free{" "}
          <a href="https://www.bootstrapdash.com/" target="_blank">
            Bootstrap dashboard templates
          </a>{" "}
          from Bootstrapdash.com
        </span>
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer