import React from 'react'
import Sidebar from '../common/sidebar'
import Header from '../common/header'

import Footer from '../common/footer'

const Homepage = ({children}) => {
    return (
        <div className="container-scroller d-flex">
                <Sidebar />
            <div class="container-fluid page-body-wrapper">
            {/* <Header /> */}
                <div class="main-panel">
                {/* <Body /> */}
                {children}
             
                </div>
            </div>
        </div>
    )
}

export default Homepage